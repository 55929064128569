const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  selfUrl: process.env.NEXT_PUBLIC_SELF_URL,
  brand: process.env.NEXT_PUBLIC_BRAND,
  brandName: process.env.NEXT_PUBLIC_BRAND_NAME,
  GTM: process.env.NEXT_PUBLIC_GTM,
  GA4: process.env.NEXT_PUBLIC_GA4,
  env: process.env.NODE_ENV,
  brandURL: process.env.NEXT_PUBLIC_BRAND_URL,
  password: process.env.NEXT_PUBLIC_SHOPIFY_PASSWORD,
  shopifyApiUrl: process.env.NEXT_PUBLIC_SHOPIFY_API_URL,
  brandCode: process.env.NEXT_PUBLIC_BRAND_CODE,
  sentry: process.env.NEXT_PUBLIC_SENTRY,
  sentryProject: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
  sentryOrg: process.env.NEXT_PUBLIC_SENTRY_ORG,
};

export default config;
